import request from '@/service/request'
/*  电费充值记录 api */

/* 电费缴费记录 page接口
*  @params/keyword 关键字
* */
export const pageChargingFeeRecord = (params) => {
  return request.post('/stu/stuChargingFeeRecord/page', params)
}

/* 电费缴费记录 根据id获取
*  @params/id
* */
export const chargingFeeRecordByIdApi = (id) => {
  return request.post('/stu/stuChargingFeeRecord/getDetailById', id)
}

/* 电费缴费记录 更新电费
*  @params/paramValue 电费值
* */
export const refreshChargingFeeRecordApi = (params) => {
  return request.post('/stu/stuChargingFeeRecord/updateOneDegreeHowMoney', params)
}

/* 电费缴费记录 查询电费余量
*  @regionId {string} 房间id
* */
export const getFlowBalanceByRegionIdApi = (regionId) => {
  return request.post(`/handle/stuChargingFeeRecord/getElectricDeviceVoByUserId/${regionId}`)
}

/* 电费缴费记录 查询电费余量(不会返回报错信息，查不到也会返回“查询失败”)
*  @regionId {string} 房间id
* */
export const getFlowBalanceByRegionIdNoErrorApi = (regionId) => {
  return request.post(`/handle/stuChargingFeeRecord/getElectricDeviceVoByRegionId/${regionId}`)
}

/* 电费使用记录 查询电费使用情况
*  @regionId {string} 设备id
* */
export const pageRecordByRegionIdOrDeviceId = (regionId) => {
  return request.post('/handle/electric/pageRecordByRegionIdOrDeviceId', regionId)
}
