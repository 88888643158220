<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <div class="header-button mb-1">
                <el-tag class="successNum">数据最新更新时间：{{ newsTime }}</el-tag>
                <el-tag class="successNum">剩余电量：{{ newsBalance }}</el-tag>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column type="index" label="ID" width="50" />
            <el-table-column prop="startTime" label="开始时间"></el-table-column>
            <el-table-column prop="endTime" label="结束时间"></el-table-column>
            <el-table-column prop="count" label="已用电量"></el-table-column>
            <el-table-column prop="balance" label="剩余电量"></el-table-column>
        </el-table>
    </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageRecordByRegionIdOrDeviceId } from '@/api/finance-manage/charging-fee-record-api'

export default {
    name: 'electricityBillRecords',
    mixins: [tableView],
    props: {
        regionId: String
    },
    data() {
        return {
            queryInfo: {
                regionId: this.regionId,
                pageSize: 10
            },
            newsTime: null,
            newsBalance: null,
            initFlag: false
        }
    },
    mounted() {
        this.renderTable()
    },
    methods: {
        async renderTable(pageNum) {
            await this.getTableData(pageRecordByRegionIdOrDeviceId, pageNum)
            if (!this.initFlag && this.tableData.length) {
                this.newsTime = this.tableData[0].endTime
                this.newsBalance = this.tableData[0].balance
                this.initFlag = true
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.successNum {
    margin: 5px 20px 0 0;
}
</style>
