<!-- 水电表 -->
<template>
  <el-dialog class="electric-water-meter-dialog"
    :title="`水电表-宿舍：${title.dormitoryName || null}第${title.floorNum || 0}层${title.roomName}【${title.dormitoryRoomType ? title.dormitoryRoomType.roomTypeName : '暂无房间'}】`"
    :visible.sync="show" :close-on-click-modal="false" width="820px" @close="close">
    <el-tabs v-model="actTab" type="card" v-if="id">
      <!-- 不存在 title.electricDeviceId和title.regionId 时 隐藏除了 水电表设备码 模块以外的所有模块-->
      <el-tab-pane label="电费充值记录" name="rechargeRecord" v-if="title.electricDeviceId && title.regionId">
        <charging-fee-record ref="rechargeRecordRef" :region-id="title.regionId" :id="id" />
      </el-tab-pane>
      <el-tab-pane label="电费赠送记录" name="freeRechargeRecord" v-if="title.electricDeviceId && title.regionId">
        <free-electricity-recharge-view :room-id="id" />
      </el-tab-pane>
      <!--    显示本模块的判断：
              1.非院系专属宿舍
              2.非院系管理员
              3.该宿舍属于本院系分配的宿舍 -->
      <el-tab-pane label="水电表设备码" name="form"
        v-if="!title.collegeId || !$store.state.login.userInfo.collegeId || $store.state.login.userInfo.collegeId === title.collegeId">
        <set-dorm-device-id-form ref="setDormDeviceFormRef" v-if="title" :parent-data="title" @on-close="closed" />
      </el-tab-pane>
      <!--  title.electricDeviceId=存在电表    -->
      <el-tab-pane label="设置电表度数" name="setElectricMeter" v-permission="['electricMeter:batch:clear']"
        v-if="title.electricDeviceId && title.regionId">
        <el-form ref="form" :model="formData" :rules="rules" label-width="240px" style="margin-top: 40px;">
          <el-form-item label="房间号(厂家标识)" prop="regionId">
            <div class="form-item-read">{{ title.regionId }}</div>
          </el-form-item>
          <el-form-item :label="`设置电表度数(原度数：${title.flowBalance})`" prop="value">
            <tool-tips-group is-flex
              tips-content="请注意：<br/>在电表设置到执行之间有一个<b style='color:var(--color-danger);'>6分钟</b>的等待期。<br/>在这段时间内，如果宿舍继续用电，将会产生一定的误差。">
              <el-input v-model.trim="formData.value" />
            </tool-tips-group>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="电表设置记录" name="electricMeterSetRecord" v-permission="['electricMeter:batch:clear']"
        v-if="title.electricDeviceId && title.regionId">
        <electric-meter-set-record-view :room-id="id" />
      </el-tab-pane>
      <el-tab-pane label="电量记录" v-if="title.electricDeviceId && title.regionId">
        <electricity-bill-records name="electricityBillRecords" :region-id="title.regionId" />
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <div class="font-center">
        <el-button @click="close">关 闭</el-button>
        <!--  水电表设备码修改后在此保存  -->
        <el-button v-if="actTab === 'form'" type="primary"
          @click="$refs.setDormDeviceFormRef.save('水电表信息', addDormRoom, '水电表信息更新成功！')">保 存
        </el-button>
        <!-- 设置电表度数  -->
        <el-button v-if="actTab === 'setElectricMeter'" type="success"
          @click="save('手动设置电表度数（请注意：设置后到执行需要6分钟的处理时间。期间内，如果宿舍继续用电，将产生一定的误差！）', setSingleElectricMeterApi, '电表度数设置成功，将在15分钟内生效！')">更新电表
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { addDormRoom } from '@/api/dorm'
import FreeElectricityRechargeView from '@/views/pages/dormManage/dialog/freeElectricityRechargeView.vue'
import electricityBillRecords from '@/views/pages/dormManage/dialog/electricityBillRecords.vue'
import SetDormDeviceIdForm from '@/views/pages/dormManage/dialog/setDormDeviceIdForm.vue'
import { setSingleElectricMeterApi } from '@/api/dorm/set-electric-meter-api'
import ElectricMeterSetRecordView from '@/views/pages/dormManage/dialog/electricMeterSetRecordView.vue'
import ChargingFeeRecord from '@/views/pages/financeManage/chargingFeeRecord/chargingFeeRecord.vue'

export default {
  name: 'electricWaterMeterDialog',
  components: {
    ChargingFeeRecord,
    ElectricMeterSetRecordView,
    SetDormDeviceIdForm,
    FreeElectricityRechargeView,
    electricityBillRecords
  },
  mixins: [dialog],
  props: {
    userTypeList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      actTab: 'rechargeRecord',
      formData: {
        roomIds: [''],
        value: undefined
      },
      rules: {
        value: [
          {
            required: true,
            message: '请输入要设置的电表度数',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorTwoDecimalPlacesObj,
          {
            validator: (rule, value, callback) => {
              if (value && (Number(value) < 0 || Number(value) > 650)) {
                return callback(new Error('仅支持0~650以内的数值!'))
              } else {
                return callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.id) {
        this.$message.error('请求失败，请重试！')
      }
      this.preTitle = '更新'
      // 安装了电表（存在regionId和 electricDeviceId）时，获取电表信息
      if (this.title.regionId && this.title.electricDeviceId) {
        this.formData.roomIds = [this.id]
      } else {
        this.actTab = 'form'
      }
    })
  },
  methods: {
    addDormRoom, // 保存api
    setSingleElectricMeterApi// 设置单个电表度数
  }
}
</script>
<style lang="scss">
.electric-water-meter-dialog {
  .el-dialog {
    margin: 10px auto 20px !important;

    .el-dialog__body {
      min-height: 687px;

      .charging-fee-record {
        padding-top: 0;
      }
    }
  }
}
</style>
